/* Base styling for nav and menu */
@media (max-width: 1440px) {

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth
  }
  
  nav {
    background: linear-gradient(135deg, #0066cc, #3399ff); /* Cool gradient background */
    color: #ffffff; /* White text color for contrast */
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: auto;
    transition: transform 0.3s ease-in-out; /* Transition for zoom effect */
  }
  
  nav.zoom-out {
    transform: scale(0.95); /* Zoom out effect */
  }
  
  .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    color: #ffffff; /* White color for the logo text */
    letter-spacing: 1.2px;
    font-family: "Quicksand", sans-serif;
  }
  
  .logo a {
    text-decoration: none;
    color: #ffffff; /* White color for the logo text */
  }
  
  .nav-links {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links ul {
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin: 0 15px;
    list-style: none;
    cursor: pointer;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff; /* White color for link text */
    font-size: 17px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding: 5px 0;
  }
  
  .nav-links a:hover {
    color: #ffff99; /* Light yellow color on hover */
    border-bottom: 2px solid #ffffff; /* Underline effect on hover */
  }

  .intro-content{    
    margin-left: 100px;
    margin-top: 250px;
  }

  .para{
    font-size: 25px;
    font-weight: 500;
    line-height: 0.4;
    
  }
  .para1{
    font-size: 30px;
    font-weight: 500;
    line-height: 0.8;
  }
  .span1{
    font-size: 45px;
    color:#0066cc;
    font-weight: 600;
    line-height: 0.8;
  }
  .para2{
    font-size: 45px;
    font-weight: 600;
    line-height: 0.8;
  }
  .para3{
    font-size: 17px;
    font-weight: 500;
    width: 42%;
    color: #818589;
  }
.img1{
    width: 37%;   
    animation: fadeIn 1s ease-in;
    margin-top: -400px;
    margin-left: 750px;
    
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.z{
    width: 100%;
    /* background-color:white; */
    
}
.button1{
    position: relative;
    overflow: hidden;
    height: 3rem;
    padding: 0 2rem;
    border-radius: 1.5rem;
    background:linear-gradient(135deg, #0066cc, #3399ff);
    background-size: 400%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-top:10px;
    
    width: 15%;
    
}
.button1:hover {
    color: #0066cc !important;
    font-size: 17px;
    font-weight: 600;
}

.button1:hover::before {
    transform: scaleX(1);
    font-weight: 600;
    color: black !important;
}

.button1-content {
    position: relative;
    z-index: 1;
}

.button1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    color: black !important;
    border: 1px solid #0066cc !important;
    background: linear-gradient(82.3deg,
            rgb(255, 255, 255) 10.8%,
            rgb(255, 255, 255) 94.3%);
    transition: all 0.475s;
    font-weight: 600;
}
.button1{
  animation: fadeIn 1s ease-in;
  /* animation-delay: 2s;  */
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.para, .button1, .para1, .para2, .para3{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}



@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
.x{
  width: 100%;
}



/*------------------ about me------------------- */



.about{
  text-align: center;
  color: white;
  font-size: 38px;
  font-weight: bold;
  margin-top: 30px;
}

.y{
  width: 100%;
  background:linear-gradient(135deg, #0066cc, #1d8bf8);
  margin-top: 140px;
}
.img2{
  width: 45%;
  margin-top: -410px;
  margin-left: -30px;
  animation: fadeIn 1s ease-in;
}
.para4{
  color: white;
  width: 50%;
  margin-left: 630px;
  letter-spacing: 0.3x;
}
.line1{
  /* border: 2px solid gray; */
  width: 100%;
  margin-top: 0px;
  border: white;
}
.tab-titles{
  display: flex;
  margin: 20px 0 40px;
  margin-left: 630px;
  color: white;
}
.tab-links{
  margin-right: 50px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position:relative ;
  
}
.tab-links::after{
  content: '';
  width: 0;
  height: 3px;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}
.tab-links.active-link::after{
  width: 50%;
}
.tab-contents{
  margin-top: -30px;
  display: none;
 
}
.tab-contents ul li{
 list-style: none;
 color: #1f1f1f;
 font-size: 13px;
 font-weight: 600;
 margin: 10px 0;
 margin-left: 597px;
}
.tab-contents ul li span{
  color: white;
  font-size: 14px;
  font-weight: 500; 
}
.tab-contents.active-tab{
  display: block;
}



.about,.para4, .line1, .tab-titles{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
/* ----------------Experience--------------- */


.w{
  background-color: #ebebeb;
  width: 100%; 
  height: 570px; 
  
}
.exper{
  margin-left: 100px;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  padding-top: 20px;
}
.para5{
  color: black;
  margin-left: 100px;
  font-size: 30px;
  font-weight: bold;
}
.para6{
  margin-left: 100px;
  color: #0066cc;
  font-size: 25px;
  font-weight: bold;
}
.para7{
  color: #1f1f1f;
  margin-left: 100px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
}
.para8{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top: 60px;
  padding-bottom: 20px;
}
.img3{
  width: 25%;
  animation: fadeIn 1s ease-in;
  margin-right: 150px;
  margin-top: -300px;
  float: right;  
}

.carousel-container{
  margin-bottom: 50px;
}
.carousel2{
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  margin-top: 20px;
  
}
.carousel:hover .carousel__item {
  animation-play-state: paused !important;
}
.carousel__list {
  display: flex;
  width: 100%;
  
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.carousel__item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  text-align: center;
  font-size: 35px;
  
  
  animation: slide var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))
  ) !important;
}
.carousel.reverse .carousel__item {
  animation: slideReverse var(--duration) linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -5);
  }
}

/*--------------- project------------- */
.projects{
  width: 100%;
}
.project{
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:10px;  
  margin-left: 100px;
}
.para9{
  color: black;
  margin-top:-15px;  
  font-size: 30px;
  font-weight: bold;
  margin-left: 100px;
}
.para10{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 50%;
  margin-left: 100px;
}
.para11{
  color: black;
  margin-top:80px;  
  font-size: 30px;
  font-weight: bold;
  margin-left: 1160px;
  
}
.para12{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 20%;
  margin-left: 1055px;
  text-align: right;
  margin-bottom: 50px;
  text-align: justify;
}
/* Slider */
.slider {
  height: auto;  
  width: 86%;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(250px * 35); /* Total width of all items combined */
  animation: scroll-right-left 15s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll-right-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9)); /* Scrolls from right to left */
  }
}

.slide {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.slide img {
  width: 100%;
  transition: transform 1s;
}

.slide img:hover {
  transform: translateZ(20px);
}

/* Slider1 */
.slider1 {
  height: auto;  
  width: 65%;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  margin-top: -250px;
  margin-left: 100px;
}

.slide1-track1 {
  
  display: flex;
  width: calc(250px * 20); /* Total width of all items combined */
  animation: scroll-left-right 15s linear infinite;
}

.slide1-track1:hover {
  animation-play-state: paused;
}

@keyframes scroll-left-right {
  0% {
    transform: translateX(calc(-250px * 9)); /* Start with the content partially off-screen to the left */
  }
  100% {
    transform: translateX(0); /* End with the content fully visible */
  }
}

.slide1 {
  width: 100%;  
  display: flex;
  align-items: center;  
  perspective: 100px;
}

.slide1 img {
  width: 80%;  
  transition: transform 1s;
}

.slide1 img:hover {
  transform: translateZ(20px);
}


.poject, .para9, .para10, .para11, .para12 {
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}



/* ---------contact---------- */
.bgcontact{
  background-color: #ebebeb;
  width: 100%;
  margin-bottom: -140px;
}
.contact{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:30px;
  animation: fadeIn 1s ease-in;  
  margin-bottom: 20px;
}
.form{
  width: 50%;
 margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
}

.button2{
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background:linear-gradient(135deg, #0066cc, #3399ff);
  background-size: 400%;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
  width: 20%;
  margin-bottom: 20px;
  
}
.button2:hover {
  color: #0066cc !important;
  font-size: 17px;
  font-weight: 600;
}

.button2:hover::before {
  transform: scaleX(1);
  font-weight: 600;
  color: black !important;
}

.button2-content {
  position: relative;
  z-index: 1;
}

.button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  color: black !important;
  border: 1px solid #0066cc !important;
  background: linear-gradient(82.3deg,
          rgb(255, 255, 255) 10.8%,
          rgb(255, 255, 255) 94.3%);
  transition: all 0.475s;
  font-weight: 600;
}
.button2{
animation: fadeIn 1s ease-in;
/* animation-delay: 2s;  */
}

@keyframes slideDown {
0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
}
.end{
  background-color: #0066cc;
  margin-top: 120px;
  padding-top: 5px;
}

.font{
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 40px;
  
}
.font i{
  letter-spacing: 30px;
  font-size: 20px;
 cursor: pointer;
 color: white;
 transition: all ease-in-out 0.3s; 
}
.font i:hover {
  transform: translateY(-3px);
}
.footer {
  text-align: center; 
  margin-bottom: 40px;
}


.ul1 {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  display: flex; 
  justify-content: center; 
}


.li1 {
  margin:0 15px; 
  font-size: 16px; 
  font-weight: 500;
  color: white;
}


.li1:hover {
  text-decoration: underline; 
  cursor: pointer; 
}

.copyrights{
  text-align: center;
  color: white;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: -40px;
  padding-bottom: 20px;
}
}

@media (max-width: 1024px) {


  nav {
    background: linear-gradient(135deg, #0066cc, #3399ff); /* Cool gradient background */
    color: #ffffff; /* White text color for contrast */
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: auto;
    transition: transform 0.3s ease-in-out; /* Transition for zoom effect */
  }
  
  nav.zoom-out {
    transform: scale(0.95); /* Zoom out effect */
  }
  
  .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    color: #ffffff; /* White color for the logo text */
    letter-spacing: 1.2px;
    font-family: "Quicksand", sans-serif;
  }
  
  .logo a {
    text-decoration: none;
    color: #ffffff; /* White color for the logo text */
  }
  
  .nav-links {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links ul {
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin: 0 15px;
    list-style: none;
    cursor: pointer;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff; /* White color for link text */
    font-size: 17px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding: 5px 0;
  }
  
  .nav-links a:hover {
    color: #ffff99; /* Light yellow color on hover */
    border-bottom: 2px solid #ffffff; /* Underline effect on hover */
  }

  .intro-content{    
    margin-left: 80px;
  }

  .para{
    font-size: 22px;
    font-weight: 500;
    line-height: 0.4;
    margin-top: -50px;
    
  }
  .para1{
    font-size: 27px;
    font-weight: 500;
    line-height: 0.8;
  }
  .span1{
    font-size: 40px;
    color:#0066cc;
    font-weight: 600;
    line-height: 0.8;
  }
  .para2{
    font-size: 40px;
    font-weight: 600;
    line-height: 0.8;
  }
  .para3{
    font-size: 15px;
    font-weight: 500;
    width: 52%;
    color: #818589;
  }
.img1{
    width: 35%;   
    animation: fadeIn 1s ease-in;
    margin-left: 600px;
    margin-top: -340px;
   
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.z{
    width: 100%;
    background-color:white;
    
}
.button1{
    position: relative;
    overflow: hidden;
    height: 3rem;
    padding: 0 2rem;
    border-radius: 1.5rem;
    background:linear-gradient(135deg, #0066cc, #3399ff);
    background-size: 400%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-top:10px;
    
    width: 30%;
    
}
.button1:hover {
    color: #0066cc !important;
    font-size: 17px;
    font-weight: 600;
}

.button1:hover::before {
    transform: scaleX(1);
    font-weight: 600;
    color: black !important;
}

.button1-content {
    position: relative;
    z-index: 1;
}

.button1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    color: black !important;
    border: 1px solid #0066cc !important;
    background: linear-gradient(82.3deg,
            rgb(255, 255, 255) 10.8%,
            rgb(255, 255, 255) 94.3%);
    transition: all 0.475s;
    font-weight: 600;
}
.button1{
  animation: fadeIn 1s ease-in;
  /* animation-delay: 2s;  */
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.para, .button1, .para1, .para2, .para3{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}



@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
.x{
  width: 100%;
}
.y{
  width: 100%;
  background:linear-gradient(135deg, #0066cc, #1d8bf8);
}
.line{
  border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
  width: 100%;
  margin-top: 130px;
}


/*------------------ about me------------------- */

.about{
  text-align: center;
  color: white;
  font-size: 35px;
  font-weight: bold;
  margin-top:10px;
}


.img2{
  width: 47%;
  margin-top: -300px;
  animation: fadeIn 1s ease-in;
}
.para4{
  color: white;
  width: 50%;
  margin-left: 430px;
  letter-spacing: 0.3x;
  font-size: 15px;
  margin-top: -20px;
}
.line1{
  /* border: 2px solid gray; */
  width: 100%;
  margin-top: 0px;
}
.tab-titles{
  display: flex;
  margin: 20px 0 40px;
  margin-top: -12px;
  margin-left: 430px;
  color: white;
}
.tab-links{
  margin-right: 50px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position:relative ;
}
.tab-links::after{
  content: '';
  width: 0;
  height: 3px;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}
.tab-links.active-link::after{
  width: 50%;
}
.tab-contents{
  margin-top: -30px;
  display: none;
}
.tab-contents ul li{
 list-style: none;
 color: #1f1f1f;
 font-size: 13px;
 font-weight: 600;
 margin: 10px 0;
 margin-top: -10px;
 margin-left: 400px;
}
.tab-contents ul li span{
  color: white;
  font-size: 14px;
  font-weight: 500; 
}
.tab-contents.active-tab{
  display: block;
}



.about,.para4, .line1, .tab-titles{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}



/* *******experience********/

.w{
  background-color: #ebebeb;
  width: 100%;  
  
}
.exper{
  margin-left: 100px;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  padding-top: 20px;
}
.para5{
  color: black;
  margin-left: 100px;
  font-size: 30px;
  font-weight: bold;
}
.para6{
  margin-left: 100px;
  color: #0066cc;
  font-size: 25px;
  font-weight: bold;
}
.para7{
  color: #1f1f1f;
  margin-left: 100px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
}
.para8{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top: 60px;
  padding-bottom: 20px;
}
.img3{
  width: 25%;
  animation: fadeIn 1s ease-in;
  margin-right: 150px;
  margin-top: -300px;
  float: right;  
}

.carousel-container{
  margin-bottom: 50px;
}
.carousel2{
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  margin-top: 20px;
  
}
.carousel:hover .carousel__item {
  animation-play-state: paused !important;
}
.carousel__list {
  display: flex;
  width: 100%;
  
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.carousel__item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  text-align: center;
  font-size: 35px;
  
  
  animation: slide var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))
  ) !important;
}
.carousel.reverse .carousel__item {
  animation: slideReverse var(--duration) linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -5);
  }
}

/*--------------- project------------- */

.project{
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:10px;  
  margin-left: 70px;
}
.para9{
  color: black;
  margin-top:-15px;  
  font-size: 30px;
  font-weight: bold;
  margin-left: 70px;
}
.para10{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: 70px;
}
.para11{
  color: black;
  margin-top:80px;  
  font-size: 30px;
  font-weight: bold;
  margin-left: 780px;
  
}
.para12{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 35%;
  margin-left: 610px;
  text-align: right;
  margin-bottom: 50px;
  text-align: justify;
}
/* Slider */
.slider {
  height: auto;  
  width: 86%;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(250px * 35); /* Total width of all items combined */
  animation: scroll-right-left 15s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll-right-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9)); /* Scrolls from right to left */
  }
}

.slide {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.slide img {
  width: 100%;
  transition: transform 1s;
}

.slide img:hover {
  transform: translateZ(20px);
}

/* Slider1 */
.slider1 {
  height: auto;  
  width: 50%;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  margin-top: -250px;
  margin-left: 80px;
}

.slide1-track1 {
  
  display: flex;
  width: calc(250px * 20); /* Total width of all items combined */
  animation: scroll-left-right 15s linear infinite;
}

.slide1-track1:hover {
  animation-play-state: paused;
}

@keyframes scroll-left-right {
  0% {
    transform: translateX(calc(-250px * 9)); /* Start with the content partially off-screen to the left */
  }
  100% {
    transform: translateX(0); /* End with the content fully visible */
  }
}

.slide1 {
  width: 100%;  
  display: flex;
  align-items: center;  
  perspective: 100px;
}

.slide1 img {
  width: 80%;  
  transition: transform 1s;
}

.slide1 img:hover {
  transform: translateZ(20px);
}



.poject,.para9, .para10, .para11,.para12{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
/* ---------contact---------- */
.bgcontact{
  background-color: #ebebeb;
  width: 100%;
  margin-bottom: -140px;
}
.contact{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:50px;
  padding-top: 30px;
  animation: fadeIn 1s ease-in;  
  margin-bottom: 20px;
}
.form{
  width: 50%;
 margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
}



.font{
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 40px;
  
}
.font i{
  letter-spacing: 30px;
  font-size: 20px;
 cursor: pointer;
 color: white;
 transition: all ease-in-out 0.3s; 
}
.font i:hover {
  transform: translateY(-3px);
}
.footer {
  text-align: center; 
  margin-bottom: 40px;
}


.ul1 {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  display: flex; 
  justify-content: center; 
}


.li1 {
  margin:0 15px; 
  font-size: 16px; 
  font-weight: 500;
  color: white;
}


.li1:hover {
  text-decoration: underline; 
  cursor: pointer; 
}

.copyrights{
  text-align: center;
  color: white;
  font-weight: 500;
  margin-top: 10px;
}
.button2{
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background:linear-gradient(135deg, #0066cc, #3399ff);
  background-size: 400%;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  display: block;
  justify-content: center;
  width: 20%;

  
}
.button2:hover {
  color: #0066cc !important;
  font-size: 17px;
  font-weight: 600;
}

.button2:hover::before {
  transform: scaleX(1);
  font-weight: 600;
  color: black !important;
}

.button2-content {
  position: relative;
  z-index: 1;
}

.button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  color: black !important;
  border: 1px solid #0066cc !important;
  background: linear-gradient(82.3deg,
          rgb(255, 255, 255) 10.8%,
          rgb(255, 255, 255) 94.3%);
  transition: all 0.475s;
  font-weight: 600;
}
.button2{
animation: fadeIn 1s ease-in;
/* animation-delay: 2s;  */
}

@keyframes slideDown {
0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
}

}


 
  /* Responsive Design */
  @media (max-width: 768px) {
    .nav-wrapper {
      flex-direction: column; /* Stack items vertically */
    }
    
  /* Mobile Menu Icon */

  .container{
    width: 100%;
  }
  .mobile-menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;   
    justify-content: end;
    
    
  }
  .bar {
    background-color: #ffffff; /* White color for the hamburger icon */
    height: 3px;
    width: 25px;
    margin: 3px 0;   
   
  
  }
  .logo {
    margin:0;
    font-size: 25px;
    font-weight: bold;
    color: #ffffff; /* White color for the logo text */
    letter-spacing: 1.2px;
    font-family: "Quicksand", sans-serif;
    
  }  
  .logo a {
    text-decoration: none;
    color: #ffffff; /* White color for the logo text */
  }
    .intro-content{
      margin-top: 150px;
      margin-left: 50px;
    }
  
    .para{
      font-size: 20px;
      font-weight: 500;
      line-height: 0.4;
      
    }
    .para1{
      font-size: 20px;
      font-weight: 500;
      line-height: 0.8;
    }
    .span1{
      font-size: 35px;
      color:#0066cc;
      font-weight: 600;
      line-height: 0.8;
    }
    .para2{
      font-size: 25px;
      font-weight: 600;
      line-height: 0.8;
    }
    .para3{
      font-size: 15px;
      font-weight: 500;
      width: 50%;
      color: #818589;
    }
  .img1{
      width: 40%;
      margin-top:-380px;      
      margin-left: 450px;
  }
  
  .z{
      width: 100%;
      background-color:white;
      
  }
  .button1{
      position: relative;
      overflow: hidden;
      height: 2.2rem;
      padding: 0 2rem;
      border-radius: 1.5rem;
      background:linear-gradient(135deg, #0066cc, #3399ff);
      background-size: 400%;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      border: none;
      cursor: pointer;
      margin-top:10px;      
      width: 22%;
      
      
  }
  .button1:hover {
      color: #0066cc !important;
      font-size: 17px;
      font-weight: 600;
  }
  
  .button1:hover::before {
      transform: scaleX(1);
      font-weight: 600;
      color: black !important;
  }
  
  .button1-content {
      position: relative;
      z-index: 1;
  }
  
  .button1::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      color: black !important;
      border: 1px solid #0066cc !important;
      background: linear-gradient(82.3deg,
              rgb(255, 255, 255) 10.8%,
              rgb(255, 255, 255) 94.3%);
      transition: all 0.475s;
      font-weight: 600;
  }
  .button1{
    animation: fadeIn 1s ease-in;
    /* animation-delay: 2s;  */
  }
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .para, .button1, .para1, .para2, .para3{
    opacity: 0;
    animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
  }
  
  
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-30px); /* Start from above */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at normal position */
    }
  }
  .x{
    width: 100%;
  }
  .y{
    width: 100%;
    background:linear-gradient(135deg, #0066cc, #1d8bf8);
  }
  .line{
    border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
    width: 100%;
    margin-top: 130px;
  }

  
    .nav-links {
      display: none; /* Hidden by default */
      position: absolute;
      top: 60px;
      right: 0;
      background: linear-gradient(135deg, #0066cc, #3399ff); /* Gradient for mobile menu */
      width: 100%;
      text-align: center;
      flex-direction: column; /* Stack links vertically */
      padding: 20px 0;
      z-index: 1000; /* Ensure it overlays other content */
      transition: opacity 0.5s ease-in-out; /* Smooth transition */
    }
  
    .nav-links.active {
      display: flex; /* Show when active */
      opacity: 1; /* Ensure opacity is set to fully visible */
      width: 100%;
     
    }
  
    .nav-links ul {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;
    }
  
    .nav-links li {
      margin: 15px 0;
      opacity: 0; /* Start with links hidden */
      animation: fadeIn 0.5s forwards; /* Animation for fading in */
      width: 100%;
    }
  
    .nav-links li:nth-child(1) {
      animation-delay: 0.1s; /* Staggered animation delay */
      width: 100%;
    }
  
    .nav-links li:nth-child(2) {
      animation-delay: 0.2s;
      width: 100%;
    }
  
    .nav-links li:nth-child(3) {
      animation-delay: 0.3s;
      width: 100%;
    }
  
    .nav-links li:nth-child(4) {
      animation-delay: 0.4s;
      width: 100%;
    }
  
    .nav-links li:nth-child(5) {
      animation-delay: 0.5s;
      width: 100%;
    }
  
    .nav-links a {
      text-decoration: none;
      color: #ffffff; /* White color for link text */
      font-size: 18px;
      padding: 10px 20px;
      display: block; /* Block display for full-width clickable area */
      border: 1px solid transparent; /* Border for visual consistency */
      border-radius: 4px; /* Rounded corners for links */
      transition: background-color 0.3s ease, color 0.3s ease;
      width: 100%;
    }
  
    .nav-links a:hover {
      background-color: #ffffff; /* White background on hover */
      color: #0066cc; /* Dark blue text color on hover */
      width: 100%;
    }
  
    .mobile-menu-icon {
      display: flex; /* Show the hamburger menu icon */
      
    }
    Keyframes for fade-in animation
  /* @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px); 
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
    
  } */
  .img1{
    width: 40%;
    margin-top:-380px;      
    margin-left: 400px;
}
/* about */
  /* -----about------ */
  .line{
    margin-top: 100px;
    margin-bottom: -20px;
  }
  .img2{
    width: 55%;
   margin-left: -50px;
   margin-top: -260px;
    
  }
  .about{
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: bold;    
    margin-bottom: 30px;
  }
  .para4{
    color: white;
    width: 93%;
    margin-left: 320px;
    letter-spacing: 0.3x;
    width: 57%;
    font-size: 13px;
    margin-bottom: -13px;
  }
  .tab-contents ul li{
    list-style: none;
    color: #1f1f1f;
    font-size: 11px;
    font-weight: 600;
    margin: 10px 0;
    margin-left: 300px;
   }
   .tab-contents ul li span{
     color: white;
     font-size: 11px;
     font-weight: 500; 
   }
   .tab-titles{
    display: flex;
    margin: 18px 0 40px;
    margin-left: 325px;
    color: white;
  }


/* -----------experience---------------- */

.w{
  background-color: #ebebeb;
  width: 100%;  
  height: 520px;
  
}
.exper{
  margin-left: 70px;
  color: #0066cc;
  font-size: 30px;
  font-weight: bold;
  padding-top: 20px;
}
.para5{
  color: black;
  margin-left: 70px;
  font-size: 27px;
  font-weight: bold;
}
.para6{
  margin-left: 70px;
  color: #0066cc;
  font-size: 25px;
  font-weight: bold;
}
.para7{
  color: #1f1f1f;
  margin-left: 100px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
}
.para8{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top: 20px;
  padding-bottom: 20px;
}
.img3{
  width: 22%;
  animation: fadeIn 1s ease-in;
  margin-right: 70px;
  margin-top: -280px;
  float: right;  
}

.carousel-container{
  padding-bottom: 40px;
}
.carousel2{
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  margin-top: 20px;
  
}
.carousel:hover .carousel__item {
  animation-play-state: paused !important;
}
.carousel__list {
  display: flex;
  width: 100%;
  
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.carousel__item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  text-align: center;
  font-size: 35px;
  
  
  animation: slide var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))
  ) !important;
}
.carousel.reverse .carousel__item {
  animation: slideReverse var(--duration) linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -5);
  }
}

  /* *****project****** */
  .project{
    color: #0066cc;
    font-size: 40px;
    font-weight: bold;    
    margin-left: 50px;
  }
  .para9{
    color: black;
    margin-top:-15px;  
    font-size: 30px;
    font-weight: bold;
    margin-left: 50px;
  }
  .para10{
    color: #666262;  
    font-size: 15px;
    font-weight: bold;
    line-height: 1.8;
    width: 50%;
    margin-left: 50px;
  }
  .para11{
    color: black;
    margin-top:10px;  
    font-size: 30px;
    font-weight: bold;
    margin-left: 530px;
    
  }
  .para12{
    color: #666262;  
    font-size: 15px;
    font-weight: bold;
    line-height: 1.8;
    width: 40%;
    margin-left: 410px;
  }
/* Slider */
.slider {
  height: auto;  
  width: 86%;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(200px * 30); /* Total width of all items combined */
  animation: scroll-right-left 15s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll-right-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9)); /* Scrolls from right to left */
  }
}

.slide {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.slide img {
  width: 100%;
  transition: transform 1s;
}

.slide img:hover {
  transform: translateZ(20px);
}

/* Slider1 */
.slider1 {
  height: auto;  
  width: 45%;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  margin-top: -210px;
  margin-left: 50px;
}

.slide1-track1 {
  
  display: flex;
  width: calc(200px * 20); /* Total width of all items combined */
  animation: scroll-left-right 15s linear infinite;
}

.slide1-track1:hover {
  animation-play-state: paused;
}

@keyframes scroll-left-right {
  0% {
    transform: translateX(calc(-250px * 9)); /* Start with the content partially off-screen to the left */
  }
  100% {
    transform: translateX(0); /* End with the content fully visible */
  }
}

.slide1 {
  width: 100%;  
  display: flex;
  align-items: center;  
  perspective: 100px;
}

.slide1 img {
  width: 80%;  
  transition: transform 1s;
}

.slide1 img:hover {
  transform: translateZ(20px);
}


  /* -------contact---------- */
  .bgcontact{
    background-color: #ebebeb;
    width: 100%;
    margin-bottom: -140px;
  }
  .contact{
    text-align: center;
    color: #0066cc;
    font-size: 40px;
    font-weight: bold;
    margin-top:20px;
    animation: fadeIn 1s ease-in;    
    margin-bottom: 20px;
  }

  .form{
    width: 50%;
   margin-left: auto;
   margin-right: auto;
   display: block;
    justify-content: center;
  }
      

  .line4{  
    border: 2px linear-gradient(135deg, #0066cc, #3399ff);
  }
  
  .font{
    margin-top: 40px;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 40px;
    
  }
  .font i{
    letter-spacing: 30px;
    font-size: 20px;
   cursor: pointer;
   color: white;
   transition: all ease-in-out 0.3s; 
  }
  .font i:hover {
    transform: translateY(-3px);
  }
  .footer {
    text-align: center; 
    margin-bottom: 40px;
  }
  
  
  .ul1 {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    display: flex; 
    justify-content: center; 
  }
  
  
  .li1 {
    margin:0 15px; 
    font-size: 16px; 
    font-weight: 500;
    color: white;
  }
  
  
  .li1:hover {
    text-decoration: underline; 
    cursor: pointer; 
  }
  
  .copyrights{
    text-align: center;
    color: white;
    font-weight: 500;
    margin-top: 10px;
  }
  Form{
  width: 70%;
  }
  
}

@media (max-width: 425px) {

  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
  }
  
  nav {
    background: linear-gradient(135deg, #0066cc, #3399ff); /* Cool gradient background */
    color: #ffffff; /* White text color for contrast */
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: auto;
    transition: transform 0.3s ease-in-out; /* Transition for zoom effect */
  }
  
  nav.zoom-out {
    transform: scale(0.95); /* Zoom out effect */
  }
  
  .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    color: #ffffff; /* White color for the logo text */
    letter-spacing: 1.2px;
    font-family: "Quicksand", sans-serif;
  }
  
  .logo a {
    text-decoration: none;
    color: #ffffff; /* White color for the logo text */
  }
  
  .mobile-menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .mobile-menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #ffffff; 
    margin: 4px 0;
    transition: 0.3s;
    color: #0066cc;
  }
  
  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #0066cc; /* Background color to ensure visibility */
    position: absolute;
    top: 100%;
    left: 0;
    transition: max-height 0.3s ease-in-out; /* Smooth transition for open/close */
    overflow: hidden; /* Hide overflowing content */
    max-height: 0; /* Hide links by default */
  }

  .nav-links.active {
    max-height: 500px; /* Sufficient height to display all links */
  }
  
  .nav-links ul {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  .nav-links li {
    margin: 10px 0; /* Add some vertical spacing */
    list-style: none;
    text-align: center; /* Center text */
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff; /* White color for link text */
    font-size: 17px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding: 5px 0;
  }
  
  .nav-links a:hover {
    color: #ffff99; /* Light yellow color on hover */
    border-bottom: 2px solid #ffffff; /* Underline effect on hover */
  }
  
    .para{
      font-size: 30px;
      font-weight: 500;
      line-height: 0.4;
      margin-top: -40px;
     margin-left: -15px; 
      
      
    }
    .para1{
      font-size: 30px;
      font-weight: 500;
      line-height: 0.8;
      margin-left: -15px;   
    }
    .span1{
      font-size: 40px;
      color:#0066cc;
      font-weight: 600;
      line-height: 0.8;
    }
    .para2{
      font-size: 25px;
      font-weight: 600;
      line-height: 0.8;
      margin-left: -15px;
    }
    .para3{
      font-size: 16px;
      font-weight: 500;      
      color: #6a6d70;      
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      justify-content: center;
      margin-left: -24px;
      margin-top: 200px;
       text-align: justify;
    }
  .img1{
    margin-left: 75px;
    margin-top: -600px;
    width: 65%;
  }
  
  .z{
      width: 100%;
      background-color:white;
      
  }
  .button1{
      position: relative;
      overflow: hidden;
      height: 2.2rem;
      padding: 0 2rem;
      border-radius: 1.5rem;
      background:linear-gradient(135deg, #0066cc, #3399ff);
      background-size: 400%;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      border: none;
      cursor: pointer;
      margin-top:-10px;      
      width: 40%;
      margin-left: 92px;
      
      
  }
  .button1:hover {
      color: #0066cc !important;
      font-size: 13px;
      font-weight: 600;
  }
  
  .button1:hover::before {
      transform: scaleX(1);
      font-weight: 600;
      color: black !important;
  }
  
  .button1-content {
      position: relative;
      z-index: 1;
  }
  
  .button1::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      width: 100%;
      height: inherit;
      border-radius: inherit;
      color: black !important;
      border: 1px solid #0066cc !important;
      background: linear-gradient(82.3deg,
              rgb(255, 255, 255) 10.8%,
              rgb(255, 255, 255) 94.3%);
      transition: all 0.475s;
      font-weight: 600;
  }
  .button1{
    animation: fadeIn 1s ease-in;
    /* animation-delay: 2s;  */
  }
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .para, .button1, .para1, .para2, .para3{
    opacity: 0;
    animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
  }
  
  
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-30px); /* Start from above */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at normal position */
    }
  }
  .x{
    width: 100%;
  }

  .line{
    border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
    width: 100%;
    margin-top: 130px;
  }

  
    .nav-links {
      display: none; /* Hidden by default */
      position: absolute;
      top: 60px;
      right: 0;
      background: linear-gradient(135deg, #0066cc, #3399ff); /* Gradient for mobile menu */
      width: 100%;
      text-align: center;
      flex-direction: column; /* Stack links vertically */
      padding: 20px 0;
      z-index: 1000; /* Ensure it overlays other content */
      transition: opacity 0.5s ease-in-out; /* Smooth transition */
    }
  
    .nav-links.active {
      display: flex; /* Show when active */
      opacity: 1; /* Ensure opacity is set to fully visible */
      width: 100%;
     
    }
  
    .nav-links ul {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;
    }
  
    .nav-links li {
      margin: 15px 0;
      opacity: 0; /* Start with links hidden */
      animation: fadeIn 0.5s forwards; /* Animation for fading in */
      width: 100%;
    }
  
    .nav-links li:nth-child(1) {
      animation-delay: 0.1s; /* Staggered animation delay */
      width: 100%;
    }
  
    .nav-links li:nth-child(2) {
      animation-delay: 0.2s;
      width: 100%;
    }
  
    .nav-links li:nth-child(3) {
      animation-delay: 0.3s;
      width: 100%;
    }
  
    .nav-links li:nth-child(4) {
      animation-delay: 0.4s;
      width: 100%;
    }
  
    .nav-links li:nth-child(5) {
      animation-delay: 0.5s;
      width: 100%;
    }
  
    .nav-links a {
      text-decoration: none;
      color: #ffffff; /* White color for link text */
      font-size: 18px;
      padding: 10px 20px;
      display: block; /* Block display for full-width clickable area */
      border: 1px solid transparent; /* Border for visual consistency */
      border-radius: 4px; /* Rounded corners for links */
      transition: background-color 0.3s ease, color 0.3s ease;
      width: 100%;
    }
  
    .nav-links a:hover {
      background-color: #ffffff; /* White background on hover */
      color: #0066cc; /* Dark blue text color on hover */
      width: 100%;
    }
  
    .mobile-menu-icon {
      display: flex; /* Show the hamburger menu icon */
      
    }

 
    /******************* about************* */
   

.about{
  text-align: center; 
  color: white;
  font-size: 38px;
  font-weight: bold;
  margin-top: 5px;
}

.y{
  width: 100%;
  background:linear-gradient(135deg, #0066cc, #1d8bf8);
  margin-top: 5px;
  margin-bottom: 0px;
}
.line{
  border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
  width: 100%;
  margin-top: 130px;
}

.img2{
  width: 70%;
  margin-top: 280px;
  animation: fadeIn 1s ease-in;
  display: none;
}
.para4{
  color: white;   
  letter-spacing: 0.3x;  
  margin-left:auto;
  margin-right: auto;  
  text-align: justify;
  font-size: 18px;
  width: 88%;
}
.par{
  color: white;   
  letter-spacing: 0.3x;  
  margin-left: 20px;
  margin-right: 20px;  
  text-align: justify;
}
.line1{
  /* border: 2px solid gray; */
  width: 100%;
  margin-top: 0px;
  border: white;
}
.tab-titles{
  display: flex;
  margin: 20px 0 40px;
  margin-left: 30px;
  margin-top: 30px;
  color: black
}
.tab-links{
  margin-right: 50px;
  font-size: 21px;
  font-weight: 500;
  cursor: pointer;
  position:relative ;
}
.tab-links::after{
  content: '';
  width: 0;
  height: 3px;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}
.tab-links.active-link::after{
  width: 50%;
}
.tab-contents{
  margin-top: -30px;
  display: none;
  margin-left: 5px;
}
.tab-contents ul li{
 list-style: none;
 color: #1f1f1f;
 font-size: 15px;
 font-weight: 600;
 margin: 10px 0;
}
.tab-contents ul li span{
  color: white;
  font-size: 16px;
  font-weight: 500; 
}
.tab-contents.active-tab{
  display: block;
}



.about,.para4, .line1, .tab-titles{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
/* ******experience*********/


.w{
  background-color: #ebebeb;
  width: 100%;
  margin-top: -30px;
  height: 6%;
}
.exper{ 
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:30px;  
  margin-left: 114px;
}
.para5{
  color: black;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}
.para6{
  margin-left: 125px;
  color: #0066cc;
  font-size: 25px;
  font-weight: bold;
  margin-top: -15px;
}
.para7{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #1f1f1f;
  list-style-type:none;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8;
  list-style: none;
  width: 90%;
  margin-right: auto;
  margin-left: auto;  
  padding: 20px;
  margin-bottom: 40px;
  background-color: white;
  text-align: justify;
}
.para8{
  margin-left:auto;
  margin-right: auto;;
  color: #0066cc;
  font-size: 35px;
  font-weight: bold;
  margin-top: -10px;
  
}
.img3{
  width: 80%;
  margin-top: 60px;
  animation: fadeIn 1s ease-in;
  display: none;
}
.carousel2{
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  margin-top: -10px;
}
.carousel:hover .carousel__item {
  animation-play-state: paused !important;
}
.carousel__list {
  display: flex;
  width: 100%;
  
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.carousel__item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  text-align: center;
  font-size: 30px;
  animation: slide var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))
  ) !important;
}
.carousel.reverse .carousel__item {
  animation: slideReverse var(--duration) linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}
/*****project******/
.project{
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;    
  margin-left: 150px;
  margin-top: -30px;
}
.para9{
  color: black;
  margin-top:-15px;  
  font-size: 22px;
  font-weight: bold;
  margin-left: 18px;
  margin-right: 10px;
}
.para10{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
.para11{
  color: black;
  margin-top:10px;  
  font-size: 22px;
  font-weight: bold;
  margin-left: 150px;
  
}
.para12{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
/* Slider */
.slider {
height: auto;  
width: 86%;
margin: auto;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
}

.slide-track {
display: flex;
width: calc(200px * 30); /* Total width of all items combined */
animation: scroll-right-left 15s linear infinite;
}

.slide-track:hover {
animation-play-state: paused;
}

@keyframes scroll-right-left {
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(calc(-250px * 9)); /* Scrolls from right to left */
}
}

.slide {
width: 100%;
display: flex;
align-items: center;
padding: 15px;
perspective: 100px;
}

.slide img {
width: 100%;
transition: transform 1s;
}

.slide img:hover {
transform: translateZ(20px);
}

/* Slider1 */
.slider1 {
height: auto;  
width: 80%;
margin: auto;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
margin-top:-20px;
margin-left: 50px;
}

.slide1-track1 {

display: flex;
width: calc(200px * 20); /* Total width of all items combined */
animation: scroll-left-right 15s linear infinite;
}

.slide1-track1:hover {
animation-play-state: paused;
}

@keyframes scroll-left-right {
0% {
  transform: translateX(calc(-250px * 9)); /* Start with the content partially off-screen to the left */
}
100% {
  transform: translateX(0); /* End with the content fully visible */
}
}

.slide1 {
width: 100%;  
display: flex;
align-items: center;  
perspective: 100px;
}

.slide1 img {
width: 80%;  
transition: transform 1s;
}

.slide1 img:hover {
transform: translateZ(20px);
}
.poject,.para9, .para10, .para11,.para12{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}
/* contact */
.bgcontact{
  background-color: #ebebeb;
  width: 100%;
  margin-bottom: -140px;
}
.contact{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:30px;
  animation: fadeIn 1s ease-in;  
  margin-bottom: 20px;
}
.form{
  width: 90%;
 margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
}
.button2{
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background:linear-gradient(135deg, #0066cc, #3399ff);
  background-size: 400%;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
  width: 30%; 
  
}
.button2:hover {
  color: #0066cc !important;
  font-size: 17px;
  font-weight: 600;
}

.button2:hover::before {
  transform: scaleX(1);
  font-weight: 600;
  color: black !important;
}

.button2-content {
  position: relative;
  z-index: 1;
}

.button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  color: black !important;
  border: 1px solid #0066cc !important;
  background: linear-gradient(82.3deg,
          rgb(255, 255, 255) 10.8%,
          rgb(255, 255, 255) 94.3%);
  transition: all 0.475s;
  font-weight: 600;
}
.button2{
animation: fadeIn 1s ease-in;
/* animation-delay: 2s;  */
}

@keyframes slideDown {
0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
}
.line4{  
  border: 2px linear-gradient(135deg, #0066cc, #3399ff);
  
}

.font{
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 40px;
  
}
.font i{
  letter-spacing: 30px;
  font-size: 20px;
 cursor: pointer;
 color: white;
 transition: all ease-in-out 0.3s; 
}
.font i:hover {
  transform: translateY(-3px);
}
.footer {
  text-align: center; 
  margin-bottom: 40px;
}


.ul1 {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  display: flex; 
  justify-content: center; 
}


.li1 {
  margin:0 15px; 
  font-size: 15px; 
  font-weight: 500;
  color: white;
}


.li1:hover {
  text-decoration: underline; 
  cursor: pointer; 
}

.copyrights{
  text-align: center;
  color: white;
  font-weight: 500;
  margin-top: 10px;
}

}
@media (max-width: 375px) {
/* ***nav*** */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}

nav {
  background: linear-gradient(135deg, #0066cc, #3399ff); /* Cool gradient background */
  color: #ffffff; /* White text color for contrast */
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  height: auto;
  transition: transform 0.3s ease-in-out; /* Transition for zoom effect */
}

nav.zoom-out {
  transform: scale(0.95); /* Zoom out effect */
}

.container {
  width: 90%; /* Adjusted width for better fit on smaller screens */
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 0;
  font-size: 20px; /* Slightly smaller font size for smaller screens */
  font-weight: bold;
  color: #ffffff; /* White color for the logo text */
  letter-spacing: 1.2px;
  font-family: "Quicksand", sans-serif;
}

.logo a {
  text-decoration: none;
  color: #ffffff; /* White color for the logo text */
}

.mobile-menu-icon {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.mobile-menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff; 
  margin: 4px 0;
  transition: 0.3s;
}

.nav-links {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #0066cc; /* Background color to ensure visibility */
  position: absolute;
  top: 60px; /* Adjust top to fit below the nav bar */
  left: 0;
  transition: max-height 0.3s ease-in-out; /* Smooth transition for open/close */
  overflow: hidden; /* Hide overflowing content */
  max-height: 0; /* Hide links by default */
  z-index: 999; /* Ensure it is above other content */
}

.nav-links.active {
  max-height: 500px; /* Ensure this is enough to display all links */
}

.nav-links ul {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  margin: 0;
  padding: 0;
  width: 100%;
}

.nav-links li {
  margin: 10px 0; /* Add vertical spacing */
  list-style: none;
  text-align: center; /* Center text */
}

.nav-links a {
  text-decoration: none;
  color: #ffffff; /* White color for link text */
  font-size: 16px; /* Slightly smaller font size for mobile */
  transition: color 0.3s ease, border-bottom 0.3s ease;
  padding: 10px 0; /* Increased padding for better touch targets */
}

.nav-links a:hover {
  color: #ffff99; /* Light yellow color on hover */
  border-bottom: 2px solid #ffffff; /* Underline effect on hover */
}

.para{
  font-size: 20px;
  font-weight: 500;
  line-height: 0.4;
 
  
}
.para1{
  font-size: 20px;
  font-weight: 500;
  line-height: 0.8;
}
.span1{
  font-size: 35px;
  color:#0066cc;
  font-weight: 600;
  line-height: 0.8;
}
.para2{
  font-size: 25px;
  font-weight: 600;
  line-height: 0.8;
}
.para3{
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  display: flex;
  color: #818589;
}
.img1{
  width: 70%; 
  margin-top: -350px;
  margin-left: 60px;
  display:block;
}

.z{
  width: 100%;
  background-color:white;
  
}
.button1{
  position: relative;
  overflow: hidden;
  height: 2.2rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background:linear-gradient(135deg, #0066cc, #3399ff);
  background-size: 400%;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top:-5px;      
  width: 50%;
  margin-left: 60px;
  
  
}
.button1:hover {
  color: #0066cc !important;
  font-size: 13px;
  font-weight: 600;
}

.button1:hover::before {
  transform: scaleX(1);
  font-weight: 600;
  color: black !important;
}

.button1-content {
  position: relative;
  z-index: 1;
}

.button1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  color: black !important;
  border: 1px solid #0066cc !important;
  background: linear-gradient(82.3deg,
          rgb(255, 255, 255) 10.8%,
          rgb(255, 255, 255) 94.3%);
  transition: all 0.475s;
  font-weight: 600;
}
.button1{
animation: fadeIn 1s ease-in;
/* animation-delay: 2s;  */
}

@keyframes slideDown {
0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
}

.para, .button1, .para1, .para2, .para3{
opacity: 0;
animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}



@keyframes slideDown {
0% {
  opacity: 0;
  transform: translateY(-30px); /* Start from above */
}
100% {
  opacity: 1;
  transform: translateY(0); /* End at normal position */
}
}
.x{
width: 100%;
}

.line{
border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
width: 100%;
margin-top: 130px;
}


.nav-links {
  display: none; /* Hidden by default */
  position: absolute;
  top: 60px;
  right: 0;
  background: linear-gradient(135deg, #0066cc, #3399ff); /* Gradient for mobile menu */
  width: 100%;
  text-align: center;
  flex-direction: column; /* Stack links vertically */
  padding: 20px 0;
  z-index: 1000; /* Ensure it overlays other content */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

.nav-links.active {
  display: flex; /* Show when active */
  opacity: 1; /* Ensure opacity is set to fully visible */
  width: 100%;
 
}

.nav-links ul {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.nav-links li {
  margin: 15px 0;
  opacity: 0; /* Start with links hidden */
  animation: fadeIn 0.5s forwards; /* Animation for fading in */
  width: 100%;
}

.nav-links li:nth-child(1) {
  animation-delay: 0.1s; /* Staggered animation delay */
  width: 100%;
}

.nav-links li:nth-child(2) {
  animation-delay: 0.2s;
  width: 100%;
}

.nav-links li:nth-child(3) {
  animation-delay: 0.3s;
  width: 100%;
}

.nav-links li:nth-child(4) {
  animation-delay: 0.4s;
  width: 100%;
}

.nav-links li:nth-child(5) {
  animation-delay: 0.5s;
  width: 100%;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff; /* White color for link text */
  font-size: 18px;
  padding: 10px 20px;
  display: block; /* Block display for full-width clickable area */
  border: 1px solid transparent; /* Border for visual consistency */
  border-radius: 4px; /* Rounded corners for links */
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
}

.nav-links a:hover {
  background-color: #ffffff; /* White background on hover */
  color: #0066cc; /* Dark blue text color on hover */
  width: 100%;
}

.mobile-menu-icon {
  display: flex; /* Show the hamburger menu icon */
  
}
   
/* *******about********** */
.about{  
  color:white;
  font-size: 38px;
  font-weight: bold;
  margin-top: -10px;  
}

.y{
  margin-top: 220px;
  width: 100%;
  background:linear-gradient(135deg, #0066cc, #1d8bf8);
}
.line{
  border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
  width: 100%;
  margin-top: 130px;
}

.img2{
  width: 70%;
  margin-top: 280px;
  animation: fadeIn 1s ease-in;
}
.para4{
  color: white;
  width: 80%;
  margin-left: auto;
  letter-spacing: 0.3x;
  margin-right: auto;
  font-size: 17px;
  text-align: justify;
}
.line1{
  /* border: 2px solid gray; */
  width: 100%;
  margin-top: 0px;
  border: white;
}
.tab-titles{
  display: flex;
  margin: 20px 0 40px;
  margin-left:40px;
  margin-top: 30px;
  color: black;
  
}
.tab-links{
  margin-right: 50px;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  position:relative ;
}
.tab-links::after{
  content: '';
  width: 0;
  height: 3px;
  background-color: white;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}
.tab-links.active-link::after{
  width: 50%;
}
.tab-contents{
  margin-top: -30px;
  display: none;
  margin-left: 10px;
}
.tab-contents ul li{
 list-style: none;
 color: #1f1f1f;
 font-size: 13px;
 font-weight: 600;
 margin: 10px 0;
}
.tab-contents ul li span{
  color: white;
  font-size: 17px;
  font-weight: 500; 
}
.tab-contents.active-tab{
  display: block;
}



.about,.para4, .line1, .tab-titles{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
}

/* experience */

.w{
  background-color: #ebebeb;
  width: 100%;
  margin-top: -30px;
}
.exper{ 
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:30px;  
  margin-left: 100px;
}
.para5{
  color: black;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}
.para6{
  margin-left: 115px;
  color: #0066cc;
  font-size: 24px;
  font-weight: bold;
  margin-top: -15px;
}
.para7{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #1f1f1f;
  list-style-type:none;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  list-style: none;
  width: 90%;
  margin-right: auto;
  margin-left: auto;  
  padding: 20px;
  margin-bottom: 40px;
  background-color: white;
  text-align: justify;
}
.para8{
  margin-left:auto;
  margin-right: auto;;
  color: #0066cc;
  font-size: 25px;
  font-weight: bold;
  margin-top: -20px;
  
}
.img3{
  width: 80%;
  margin-top: 60px;
  animation: fadeIn 1s ease-in;
  display: none;
}
.carousel2{
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  margin-top: -10px;
}
.carousel:hover .carousel__item {
  animation-play-state: paused !important;
}
.carousel__list {
  display: flex;
  width: 100%;
  
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.carousel__item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  text-align: center;
  font-size: 25px;
  animation: slide var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))
  ) !important;
}
.carousel.reverse .carousel__item {
  animation: slideReverse var(--duration) linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}
/* project */
.project{
  color: #0066cc;
  font-size: 30px;
  font-weight: bold;    
  margin-left: 135px;
  margin-top: -30px;
}
.para9{
  color: black;
  margin-top:-15px;  
  font-size: 18px;
  font-weight: bold;
  margin-left: 25px;
  margin-right: auto;
}
.para10{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
.para11{
  color: black;
  margin-top:10px;  
  font-size: 22px;
  font-weight: bold;
  margin-left: 120px;
  
}
.para12{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
/* Slider */
.slider {
height: auto;  
width: 86%;
margin: auto;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
}

.slide-track {
display: flex;
width: calc(200px * 30); /* Total width of all items combined */
animation: scroll-right-left 15s linear infinite;
}

.slide-track:hover {
animation-play-state: paused;
}

@keyframes scroll-right-left {
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(calc(-250px * 9)); /* Scrolls from right to left */
}
}

.slide {
width: 100%;
display: flex;
align-items: center;
padding: 15px;
perspective: 100px;
}

.slide img {
width: 100%;
transition: transform 1s;
}

.slide img:hover {
transform: translateZ(20px);
}

/* Slider1 */
.slider1 {
height: auto;  
width: 80%;
margin: auto;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
margin-top:-20px;
margin-left: 50px;
}

.slide1-track1 {

display: flex;
width: calc(200px * 20); /* Total width of all items combined */
animation: scroll-left-right 15s linear infinite;
}

.slide1-track1:hover {
animation-play-state: paused;
}

@keyframes scroll-left-right {
0% {
  transform: translateX(calc(-250px * 9)); /* Start with the content partially off-screen to the left */
}
100% {
  transform: translateX(0); /* End with the content fully visible */
}
}

.slide1 {
width: 100%;  
display: flex;
align-items: center;  
perspective: 100px;
}

.slide1 img {
width: 80%;  
transition: transform 1s;
}

.slide1 img:hover {
transform: translateZ(20px);
}


/* contact */

.bgcontact{
  background-color: #ebebeb;
  width: 100%;
  margin-bottom: -140px;
}
.contact{
  text-align: center;
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:30px;
  animation: fadeIn 1s ease-in;  
  margin-bottom: 20px;
}
.form{
  width: 90%;
 margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
}
.button2{
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background:linear-gradient(135deg, #0066cc, #3399ff);
  background-size: 400%;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-left: auto;
 margin-right: auto;
 display: block;
  justify-content: center;
  width: 30%;
  margin-bottom: 20px;
  
}
.button2:hover {
  color: #0066cc !important;
  font-size: 17px;
  font-weight: 600;
}

.button2:hover::before {
  transform: scaleX(1);
  font-weight: 600;
  color: black !important;
}

.button2-content {
  position: relative;
  z-index: 1;
}

.button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  color: black !important;
  border: 1px solid #0066cc !important;
  background: linear-gradient(82.3deg,
          rgb(255, 255, 255) 10.8%,
          rgb(255, 255, 255) 94.3%);
  transition: all 0.475s;
  font-weight: 600;
}
.button2{
animation: fadeIn 1s ease-in;
/* animation-delay: 2s;  */
}

@keyframes slideDown {
0% {
  opacity: 0;
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
}
.line4{  
  border: 2px linear-gradient(135deg, #0066cc, #3399ff);
  
}

.font{
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 30px;
  
}
.font i{
  letter-spacing: 30px;
  font-size: 18px;
 cursor: pointer;
 color: white;
 transition: all ease-in-out 0.3s; 
}
.font i:hover {
  transform: translateY(-3px);
}
.footer {
  text-align: center; 
  margin-bottom: 40px;
}


.ul1 {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  display: flex; 
  justify-content: center; 
}


.li1 {
  margin:0 15px; 
  font-size: 12px; 
  font-weight: 500;
  color: white;
}


.li1:hover {
  text-decoration: underline; 
  cursor: pointer; 
}

.copyrights{
  text-align: center;
  color: white;
  font-weight: 500;
  margin-top: 10px;
  font-size: 15px;
}



}
@media (max-width: 320px) {
  /* ***nav*** */
  
  nav {
    background: linear-gradient(135deg, #0066cc, #3399ff); /* Cool gradient background */
    color: #ffffff; /* White text color for contrast */
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: auto;
    transition: transform 0.3s ease-in-out; /* Transition for zoom effect */
  }
  
  nav.zoom-out {
    transform: scale(0.95); /* Zoom out effect */
  }
  
  .container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    color: #ffffff; /* White color for the logo text */
    letter-spacing: 1.2px;
    font-family: "Quicksand", sans-serif;
  }
  
  .logo a {
    text-decoration: none;
    color: #ffffff; /* White color for the logo text */
  }
  
  .nav-links {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links ul {
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin: 0 15px;
    list-style: none;
    cursor: pointer;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff; /* White color for link text */
    font-size: 17px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding: 5px 0;
  }
  
  .nav-links a:hover {
    color: #ffff99; /* Light yellow color on hover */
    border-bottom: 2px solid #ffffff; /* Underline effect on hover */
  }
  
  
  .nav-links {
    display: none; /* Initially hidden */
  }
  
  /* Show nav links when the menu is open */
  .nav-links.active {
    display: block;
  }
  
  /* Additional styles for mobile menu icon */
  .mobile-menu-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
   
  .para{
    font-size: 20px;
    font-weight: 500;
    line-height: 0.4;
   
    
  }
  .para1{
    font-size: 20px;
    font-weight: 500;
    line-height: 0.8;
  }
  .span1{
    font-size: 30px;
    color:#0066cc;
    font-weight: 600;
    line-height: 0.8;
  }
  .para2{
    font-size: 20px;
    font-weight: 600;
    line-height: 0.8;
     width: 100%;
  }
  .para3{
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    color: #818589;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
  }
  .img1{
    width: 70%; 
    margin-left: 60px;
   
    display:block;
  }
  
  .z{
    width: 100%;
    background-color:white;
    
  }
  .button1{
    position: relative;
    overflow: hidden;
    height: 2.2rem;
    padding: 0 2rem;
    border-radius: 1.5rem;
    background:linear-gradient(135deg, #0066cc, #3399ff);
    background-size: 400%;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-top:-10px;      
    width: 55%;
    margin-left: 40px;
    
    
  }
  .button1:hover {
    color: #0066cc !important;
    font-size: 13px;
    font-weight: 600;
  }
  
  .button1:hover::before {
    transform: scaleX(1);
    font-weight: 600;
    color: black !important;
  }
  
  .button1-content {
    position: relative;
    z-index: 1;
  }
  
  .button1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    color: black !important;
    border: 1px solid #0066cc !important;
    background: linear-gradient(82.3deg,
            rgb(255, 255, 255) 10.8%,
            rgb(255, 255, 255) 94.3%);
    transition: all 0.475s;
    font-weight: 600;
  }
  .button1{
  animation: fadeIn 1s ease-in;
  /* animation-delay: 2s;  */
  }
  
  @keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  }
  
  .para, .button1, .para1, .para2, .para3{
  opacity: 0;
  animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
  }
  
  
  
  @keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px); /* Start from above */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End at normal position */
  }
  }
  .x{
  width: 100%;
  }
  
  .line{
  border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
  width: 100%;
  margin-top: 130px;
  }
  
  
  .nav-links {
    display: none; /* Hidden by default */
    position: absolute;
    top: 60px;
    right: 0;
    background: linear-gradient(135deg, #0066cc, #3399ff); /* Gradient for mobile menu */
    width: 100%;
    text-align: center;
    flex-direction: column; /* Stack links vertically */
    padding: 20px 0;
    z-index: 1000; /* Ensure it overlays other content */
    transition: opacity 0.5s ease-in-out; /* Smooth transition */
  }
  
  .nav-links.active {
    display: flex; /* Show when active */
    opacity: 1; /* Ensure opacity is set to fully visible */
    width: 100%;
   
  }
  
  .nav-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
  }
  
  .nav-links li {
    margin: 15px 0;
    opacity: 0; /* Start with links hidden */
    animation: fadeIn 0.5s forwards; /* Animation for fading in */
    width: 100%;
  }
  
  .nav-links li:nth-child(1) {
    animation-delay: 0.1s; /* Staggered animation delay */
    width: 100%;
  }
  
  .nav-links li:nth-child(2) {
    animation-delay: 0.2s;
    width: 100%;
  }
  
  .nav-links li:nth-child(3) {
    animation-delay: 0.3s;
    width: 100%;
  }
  
  .nav-links li:nth-child(4) {
    animation-delay: 0.4s;
    width: 100%;
  }
  
  .nav-links li:nth-child(5) {
    animation-delay: 0.5s;
    width: 100%;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #ffffff; /* White color for link text */
    font-size: 18px;
    padding: 10px 20px;
    display: block; /* Block display for full-width clickable area */
    border: 1px solid transparent; /* Border for visual consistency */
    border-radius: 4px; /* Rounded corners for links */
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 100%;
  }
  
  .nav-links a:hover {
    background-color: #ffffff; /* White background on hover */
    color: #0066cc; /* Dark blue text color on hover */
    width: 100%;
  }
  
  .mobile-menu-icon {
    display: flex; /* Show the hamburger menu icon */
    
  }
     
  /* *******about********** */
  .about{
     
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;

  }
  
  .y{
    width: 100%;
    background:linear-gradient(135deg, #0066cc, #1d8bf8);
  }
  .line{
    border: 2px solid linear-gradient(135deg, #0066cc, #3399ff);
    width: 100%;
    margin-top: 130px;
  }
  
  .img2{
    width: 70%;
    margin-top: 280px;
    display: none;
    animation: fadeIn 1s ease-in;
  }
  .para4{
    color: white;
    width: 80%;
    margin-left: auto;
    letter-spacing: 0.3x;
    margin-right: auto;
    font-size: 17px;
    text-align: justify;
  }
  .line1{
    /* border: 2px solid gray; */
    width: 100%;
    margin-top: 0px;
    border: white;
  }
  .tab-titles{
    display: flex;
    margin: 20px 0 40px;
    margin-left: 30px;
    margin-top: 30px;
    color: white;
  }
  .tab-links{
    margin-right: 50px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    position:relative ;
  }
  .tab-links::after{
    content: '';
    width: 0;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: 0.5s;
  }
  .tab-links.active-link::after{
    width: 50%;
  }
  .tab-contents{
    margin-top: -30px;
    display: none;
    margin-left: 20px;
  }
  .tab-contents ul li{
   list-style: none;
   color: #1f1f1f;
   font-size: 13px;
   font-weight: 600;
   margin: 10px 0;
   margin-left: -20px;
  }
  .tab-contents ul li span{
    color: white;
    font-size: 14px;
    font-weight: 500; 
  }
  .tab-contents.active-tab{
    display: block;
  }
  
  
  
  .about,.para4, .line1, .tab-titles{
    opacity: 0;
    animation: slideDown 0.7s ease-out forwards; /* Initially no animation */
  }
  
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-30px); /* Start from above */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at normal position */
    }
  }
  
  
/* experience */


.w{
  background-color: #ebebeb;
  width: 100%;
  margin-top: -30px;
}
.exper{ 
  color: #0066cc;
  font-size: 40px;
  font-weight: bold;
  margin-top:30px;  
  margin-left: 55px;
}
.para5{
  color: black;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-left: 0px;
  margin-top: 20px;
}
.para6{
  margin-left: 75px;
  color: #0066cc;
  font-size: 23px;
  font-weight: bold;
  margin-top: -15px;
}
.para7{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #1f1f1f;
  list-style-type:none;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.8;
  list-style: none;
  width: 90%;
  margin-right: auto;
  margin-left: auto;  
  padding: 20px;
  margin-bottom: 40px;
  background-color: white;
  text-align: justify;
}
.para8{
  margin-left:auto;
  margin-right: auto;;
  color: #0066cc;
  font-size: 25px;
  font-weight: bold;
  margin-top: -10px;
  
}
.img3{
  width: 80%;
  margin-top: 60px;
  animation: fadeIn 1s ease-in;
  display: none;
}
.carousel2{
  width: 100%;
  height: var(--height);
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
  margin-top: -10px;
}
.carousel:hover .carousel__item {
  animation-play-state: paused !important;
}
.carousel__list {
  display: flex;
  width: 100%;
  
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.carousel__item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  text-align: center;
  font-size: 20px;
  animation: slide var(--duration) linear infinite;
  animation-delay: calc(
    (var(--duration) / var(--quantity)) * (var(--position) - var(--quantity))
  ) !important;
}
.carousel.reverse .carousel__item {
  animation: slideReverse var(--duration) linear infinite;
}

@keyframes slide {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}
  
/* project */
.project{
  color: #0066cc;
  font-size: 30px;
  font-weight: bold;    
  margin-left: 110px;
  margin-top: -30px;
}
.para9{
  color: black;
  margin-top:-15px;  
  font-size: 16px;
  font-weight: bold;
  margin-left: 18px;
  margin-right: 10px;
}
.para10{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
.para11{
  color: black;
  margin-top:10px;  
  font-size: 22px;
  font-weight: bold;
  margin-left: 95px;
  
}
.para12{
  color: #666262;  
  font-size: 15px;
  font-weight: bold;
  line-height: 1.8;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
/* Slider */
.slider {
height: auto;  
width: 86%;
margin: auto;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
}

.slide-track {
display: flex;
width: calc(200px * 30); /* Total width of all items combined */
animation: scroll-right-left 15s linear infinite;
}

.slide-track:hover {
animation-play-state: paused;
}

@keyframes scroll-right-left {
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(calc(-250px * 9)); /* Scrolls from right to left */
}
}

.slide {
width: 100%;
display: flex;
align-items: center;
padding: 15px;
perspective: 100px;
}

.slide img {
width: 100%;
transition: transform 1s;
}

.slide img:hover {
transform: translateZ(20px);
}

/* Slider1 */
.slider1 {
height: auto;  
width: 80%;
margin: auto;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
margin-top:-20px;
margin-left: 30px;
}

.slide1-track1 {

display: flex;
width: calc(200px * 20); /* Total width of all items combined */
animation: scroll-left-right 15s linear infinite;
}

.slide1-track1:hover {
animation-play-state: paused;
}

@keyframes scroll-left-right {
0% {
  transform: translateX(calc(-250px * 9)); /* Start with the content partially off-screen to the left */
}
100% {
  transform: translateX(0); /* End with the content fully visible */
}
}

.slide1 {
width: 100%;  
display: flex;
align-items: center;  
perspective: 100px;
}

.slide1 img {
width: 80%;  
transition: transform 1s;
}

.slide1 img:hover {
transform: translateZ(20px);
}
  
  /* contact */
  
  
  
  
  .contact{
    text-align: center;
    color: #0066cc;
    font-size: 40px;
    font-weight: bold;
    margin-top:30px;
    animation: fadeIn 1s ease-in;
    
    margin-bottom: 20px;
  }
  .form{
    width: 50%;
   margin-left: auto;
   margin-right: auto;
    display: block;
    justify-content: center;
  }
  .button2{
    position: relative;
    overflow: hidden;
    height: 3rem;
    padding: 0 2rem;
    border-radius: 1.5rem;
    background:linear-gradient(135deg, #0066cc, #3399ff);
    background-size: 400%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-left: auto;
   margin-right: auto;
    display: block;
    justify-content: center;
    width: 30%;
    margin-bottom: 20px;
    
  }
  .button2:hover {
    color: #0066cc !important;
    font-size: 17px;
    font-weight: 600;
  }
  
  .button2:hover::before {
    transform: scaleX(1);
    font-weight: 600;
    color: black !important;
  }
  
  .button2-content {
    position: relative;
    z-index: 1;
  }
  
  .button2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    color: black !important;
    border: 1px solid #0066cc !important;
    background: linear-gradient(82.3deg,
            rgb(255, 255, 255) 10.8%,
            rgb(255, 255, 255) 94.3%);
    transition: all 0.475s;
    font-weight: 600;
  }
  .button2{
  animation: fadeIn 1s ease-in;
  /* animation-delay: 2s;  */
  }
  
  @keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  }
  .line4{  
    border: 2px linear-gradient(135deg, #0066cc, #3399ff);
    
  }
  
  .font{
    margin-top: 40px;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 40px;
    
  }
  .font i{
    letter-spacing: 30px;
    font-size: 20px;
   cursor: pointer;
   color: white;
   transition: all ease-in-out 0.3s; 
  }
  .font i:hover {
    transform: translateY(-3px);
  }
  .footer {
    text-align: center; 
    margin-bottom: 40px;
  }
  
  
  .ul1 {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
    display: flex; 
    justify-content: center; 
  }
  
  
  .li1 {
    margin:0 15px; 
    font-size: 16px; 
    font-weight: 500;
    color: white;
  }
  
  
  .li1:hover {
    text-decoration: underline; 
    cursor: pointer; 
  }
  
  .copyrights{
    text-align: center;
    color: white;
    font-weight: 500;
    margin-top: 10px;
  }
  
  

  }